.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-image: url('https://images.unsplash.com/photo-1570528812862-9984124e7e22?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1850&q=80');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

input {
  width: 400px;
  height: 35px;
  border-radius: 5px;
  border: none;
  margin-right: 20px;
  opacity: 0.8;
}

button {
  font-size: 1rem;
  height: 35px;
  width: 100px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  opacity: 0.8;
}

button:hover {
  cursor: pointer;
  color: white;
  background-color: orange;
  transform: scale(1.1);
  opacity: 0.8;
}

.Items {
  cursor: pointer;
}

textDecoration {
  color: red;
}